<template>
  <div class="auto-reply">
    <p class="title">
      自动回复设置
    </p>

    <el-tabs v-model="activeTab" v-if="!noApp">
      <el-tab-pane label="关键词回复" name="key">
        <key-word-reply />
      </el-tab-pane>
      <el-tab-pane label="收到消息回复" name="receive">
        <single-reply api-key="fw-wx-auto-reply-getReceiveReply" />
      </el-tab-pane>
      <el-tab-pane label="被关注回复" name="subscribe">
        <single-reply api-key="fw-wx-auto-reply-getSubscribeReply" />
      </el-tab-pane>
    </el-tabs>

    <!-- 未选择应用时给出提示 -->
    <el-empty v-else>
      <template #description>
        <span class="no-app-notice">
          当前暂未选择应用,请在右上角选择需要操作的应用
        </span>
      </template>
    </el-empty>
    
  </div>
</template>

<script>
import commonComputeds from "@/utils/commonComputeds";
export default {
  name: 'automatic-reply',
  data() {
    return {
      activeTab: 'key'
    }
  },
  computed: {
    ...commonComputeds,
  },
  components: {
    'key-word-reply': () => import('./KeywordReply'),
    'single-reply': () => import('./SingleReply')
  }
}
</script>

<style lang="less">
.auto-reply {
  padding: 0px 1rem;
  .title {
    border-bottom: 1px #ccccCc solid;
    font-weight: 600;
    font-size: 1.5rem;
    margin: 1rem 0;
    padding-bottom: 0.3rem;
  }

  .no-app-notice {
    color: red;
  }
}
</style>